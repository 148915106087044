import Axios from '../config/axios';

export const AuthLogin = (
  postData,
  callback = ({ success, data }) => {}
) => {
  Axios.post(`/users/login/`, postData)
    .then((response) => {
      callback({ success: true, data: response?.data });
    })
    .catch((error) => {
      callback({ success: false });
    });
};

export const AuthSignup = (
  postData,
  callback = ({ success, data }) => {}
) => {
  Axios.post(`/users/signup/`, postData)
    .then((response) => {
      callback({ success: true, data: response?.data });
    })
    .catch((error) => {
      callback({ success: false });
    });
};

export const AuthConfirmSignup = (
  postData,
  callback = ({ success, data }) => {}
) => {
  Axios.post(`/users/signup/verify-code/`, postData)
    .then((response) => {
      callback({ success: true, data: response?.data });
    })
    .catch((error) => {
      callback({ success: false });
    });
};

export const AuthResendSignup = (
  postData,
  callback = ({ success, data }) => {}
) => {
  Axios.post(`/users/signup/resend-code/`, postData)
    .then((response) => {
      callback({ success: true, data: response?.data });
    })
    .catch((error) => {
      callback({ success: false });
    });
};
