/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useRef, useState } from 'react';
import QRLogo from '../../images/navbar-logo.svg';
import LogoText from '../../images/logotext.svg';
// import HomeLogo from '../../images/navbar-home-logo.svg';
import AboutIcon from '../../images/navbar-about-icon.svg';
import MessageIcon from '../../images/navbar-message-icon.svg';
import DownloadIcon from '../../images/navbar-download-icon.svg';
import Avatar from '../../images/avatar.png';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { Link, useNavigate } from 'react-router-dom';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import LuggageIcon from '@mui/icons-material/Luggage';
import { Collapse, List, ListItemButton, ListItemText } from '@mui/material';
import { Auth } from 'aws-amplify';
import { PulseLoader } from 'react-spinners';
import { Store, ResetStore } from '../../StoreContext';
import ConfirmationModel from '../common/ConfirmationModel';
import { determineDeviceType } from '../../utils/functions';
import mixpanel from 'mixpanel-browser';

function Navbar() {
  const { user, loggedIn } = Store();
  const nav = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [openMenu, setOpenMenu] = React.useState(false);
  const [loggedOutMenu, setLoggedOutMenu] = React.useState(false);
  const [cancel, setCancel] = React.useState(false);

  const [loading, setLoading] = React.useState(false);

  const handleClick = () => {
    setOpen(!open);
  };
  const handleMenuClick = () => {
    setOpenMenu(!openMenu);
  };
  const handleLoggedOutMenuClick = () => {
    setLoggedOutMenu(!loggedOutMenu);
  };
  const device_type = determineDeviceType()
  const menuRef = useRef(null);
  const burgerMenu = useRef(null);
  const loggedOut = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setOpen(false);
      } if (burgerMenu.current && !burgerMenu.current.contains(event.target)) {
        setOpenMenu(false);
      } if (loggedOut.current && !loggedOut.current.contains(event.target)) {
        setLoggedOutMenu(false);
      }
    };
    window.addEventListener('click', handleClickOutside);
    return () => {
      window.removeEventListener('click', handleClickOutside);
    };
  }, [loggedOut]);
  console.log(loggedOutMenu, "open")

  const signOut = async (event) => {
    event.preventDefault();
    setTimeout(async () => {
      try {
        await Auth.signOut().finally(() => nav('/'));
      } catch (error) {
        console.log("error signing out: ", error);
        event.preventDefault();
      }
    });
  };
  return (
    <>
      <div className='Navbar_Container'>
        <div className='Navbar_LogoDiv'>
          <img className='Navbar_Logo' src={QRLogo} />
          <img className='Navbar_LogoText' src={LogoText} />
        </div>
        <div className='Navbar_MenuContainer'>
          <div className='Navbar_MenuDiv'>
            <Link to='/about' className='Navbar_MenuLinks'>
              <img src={AboutIcon} />
              <p>About Us</p>
            </Link>
            <Link to='/chat' className='Navbar_MenuLinks'>
              <img src={MessageIcon} />
              <p>Messages</p>
            </Link>
            <Link to='/store' className='Navbar_MenuLinks'>
              <ShoppingCartOutlinedIcon sx={{ color: '#fff' }} />
              <p>Store</p>
            </Link>
            {loggedIn &&
              <Link to='/mystuff' className='Navbar_MenuLinks'>
                <LuggageIcon sx={{ color: '#fff' }} />
                <p>My Stuff</p>
              </Link>}
            <a onClick={() => {
              mixpanel.track('Download app', {
                buttonName: "Download the app"
              });
            }}
              className='Navbar_MenuLinks' href={device_type === "Android" ? "https://play.google.com/store/apps/details?id=com.withered_feather_36062" : "https://testflight.apple.com/join/PK1Ua4LJ"}>
              <img src={DownloadIcon} />
              <p>Download the App</p>
            </a>
          </div>
          {!loggedIn ? (
            <ListItemButton
              ref={loggedOut}
              sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <div className='LogIn_BtnDiv' onClick={handleLoggedOutMenuClick}>
                <button
                  className='LogIn_BtnWeb'
                  onClick={() => (window.location = '/')}
                >
                  <div> Sign In</div>
                </button>
                <div className='expandIconsMob'>
                  {/* {loggedOutMenu ? (
                    <CloseIcon sx={{ color: '#ffffff' }} />
                  ) : ( */}
                  <MenuIcon
                    sx={{ color: '#ffffff' }} />
                  {/* )} */}
                </div>
              </div>
            </ListItemButton>
          ) : (
            <>
              <div ref={menuRef} className='profile_div'
              >
                <ListItemButton sx={{ width: '100%' }} onClick={handleClick}>
                  <div className='profilePic_div'
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      width: '100%',
                    }}
                  >
                    <div>
                      <img
                        className='profile_img'
                        src={user?.looser?.profile_picture
                          ? user?.looser?.profile_picture
                          : Avatar} />
                    </div>
                    <p
                      style={{
                        color: '#ffffff',
                        margin: '0 5%',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      {user?.name || 'User'}
                    </p>
                    {open || openMenu || loggedOutMenu ? (
                      <ExpandLess sx={{ color: '#ffffff' }} />
                    ) : (
                      <ExpandMore sx={{ color: '#ffffff' }} />
                    )}
                  </div>
                </ListItemButton>
              </div>
              <div ref={burgerMenu} className='menu_Icon'>
                <ListItemButton sx={{ width: '100%' }} onClick={handleMenuClick}>
                  <MenuIcon sx={{ color: '#ffffff' }} />
                </ListItemButton>
              </div>
            </>
          )}
        </div>
      </div>
      <Collapse
        className='Navbar_CollapseDiv'
        in={open || openMenu || loggedOutMenu}
        timeout='auto'
        unmountOnExit
      >
        <List disablePadding>
          {loggedIn && (
            <>
              {/* <ListItemButton> */}
              <ListItemText>
                <div className='login_name'>
                  <div className='Navbar_CollapseText'>
                    {user?.name || 'Username'}
                  </div>
                </div>
              </ListItemText>
              {/* </ListItemButton> */}

              <div className='Navbar_CollapseDivider' />
              {/* <ListItemButton> */}
              <ListItemText>
                <div className='login_name'>
                  <div className='Navbar_CollapseText'>
                    {user?.email || 'user@qrtagit.com'}
                  </div>
                </div>
              </ListItemText>
              {/* </ListItemButton> */}
              <div className='Navbar_CollapseDivider' />
            </>
          )}
          <div className='Navbar_CollapseDiv_Mob'>
            <ListItemButton>
              <ListItemText sx={{ margin: '0' }}>
                <Link to='/about' className='Navbar_CollapseText'>
                  <p>About Us</p>
                </Link>
              </ListItemText>
            </ListItemButton>
            <div className='Navbar_CollapseDivider' />
            <ListItemButton>
              <ListItemText sx={{ margin: '0' }}>
                <Link to='/chat' className='Navbar_CollapseText'>
                  <p>Messages</p>
                </Link>
              </ListItemText>
            </ListItemButton>
            <div className='Navbar_CollapseDivider' />
            <ListItemButton>
              <ListItemText sx={{ margin: '0' }}>
                <Link to='/store' className='Navbar_CollapseText'>
                  <p>Store</p>
                </Link>
              </ListItemText>
            </ListItemButton>
            <div className='Navbar_CollapseDivider' />
            {loggedIn &&
              <ListItemButton>
                <ListItemText sx={{ margin: '0' }}>
                  <Link to='/mystuff' className='Navbar_CollapseText'>
                    <p>My Stuff</p>
                  </Link>
                </ListItemText>
              </ListItemButton>}
            <div className='Navbar_CollapseDivider' />
            <ListItemButton>
              <ListItemText sx={{ margin: '0' }}>
                <a
                  onClick={() => {
                    mixpanel.track('Download app', {
                      buttonName: "Download the app"
                    })
                  }}
                  className='Navbar_CollapseText' href={device_type === "Android" ? "https://play.google.com/store/apps/details?id=com.withered_feather_36062" : "https://testflight.apple.com/join/PK1Ua4LJ"}>
                  <p>Download the App</p>
                </a>
              </ListItemText>
            </ListItemButton>
            <div className='Navbar_CollapseDivider' />
            {!loggedIn && (
              <ListItemButton onClick={() => (window.location = '/')}>
                <ListItemText>
                  <div className='Navbar_SignOutDiv'>
                    <div className='Navbar_CollapseText'> SignIn</div>
                    <div>
                      {loading && <PulseLoader size={10} color='#0A3F74' />}
                    </div>
                  </div>
                </ListItemText>
              </ListItemButton>
            )}
          </div>
          {loggedIn && (
            <ListItemButton
              onClick={() => {
                setCancel(true);
                // if (loading) {
                //   return;
                // }
                // setLoading((s) => !s);
                // Auth.signOut().finally(() => {
                //   nav("/");
                // });
                // ResetStore();
              }}
            >
              <ListItemText>
                <div className='Navbar_SignOutDiv'>
                  <div className='Navbar_CollapseText'> Sign Out</div>
                  <div>
                    {loading && <PulseLoader size={10} color='#0A3F74' />}
                  </div>
                </div>
              </ListItemText>
            </ListItemButton>
          )}
        </List>
      </Collapse>
      <ConfirmationModel
        open={cancel}
        setOpen={setCancel}
        onConfirm={() => {
          if (loading) {
            return;
          }
          setLoading((s) => !s);
          // Auth.signOut().finally(() => {
          //   nav('/');
          // });
          // localStorage.setItem('isLoggedIn', 'false');
          // if (localStorage.getItem('amplify-signin-with-hostedUI')) {
          //   localStorage.removeItem('amplify-signin-with-hostedUI');
          // }
          localStorage.setItem('isLoggedIn', 'false');
          localStorage.clear();
          setCancel(false);
          // ResetStore();
          nav('/');
          setTimeout(() => {
            window.location.reload();
          }, 300)
        }}
      ></ConfirmationModel>
    </>
  );
}

export default Navbar;
